  
  .StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    min-width: 400px;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
  .product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: linear-gradient(
      135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%
    );
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .product-info {
    padding: 0 16px;
  }
  
  h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
  }
  
  h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
    font-weight: 500;
  }