@media (max-width: 1400.98px) {
  .account-detail ul li small {
    font-size: 17px;
  }
  .list-view-order ul li figure {
    width: 178px !important;
    max-width: 178px !important;
  }
  .inclusive-text small {
    font-size: 16px !important;
  }
  .inclusive-text span {
    font-size: 17px !important;
  }
  .inclusiv-main {
    padding: 0px 23px;
  }
  .seller-qnt-des .product-selection .qunty {
    /* min-width: 81px; */
    width: 73px;
    font-size: 14px;
    margin-right: 8px;
  }
  .custom_radio {
    padding: 15px 16px 15px 15px;
    font-size: 19px;
  }
  .orders-list ul li figure {
    width: 60px;
    max-width: 60px;
  }
  .product-related-sec {
    padding: 22px 17px;
  }
  .product-related-sec h2 {
    font-size: 20px;
  }
  .delete-order li a {
    font-size: 13px;
  }
  .subtotal h2 {
    font-size: 21px;
  }
  .delete-order li {
    margin-right: 7px;
  }
  .orders-list ul figcaption {
    width: calc(100% - 60px);
    /* margin-left: 13px; */
  }
  .align-middle .btn {
    padding: 5px 12px;
    font-size: 14px;
    /* width: 100%; */
    margin-left: 0px;
    width: 109px;
  }
  .productdesc-order ul figcaption h6 a {
    font-size: 24px;
  }
  .select-payment-method .custom_radio {
    padding: 15px 12px 15px 23px;
  }
  .checkout-heading {
    font-size: 21px;
  }
  .select-payment-method .position-static .custom_radio {
    font-size: 18px;
  }
  .product-desc .image-gallery-thumbnail {
    width: 83px;
  }
  .account-detail ul li {
    font-size: 19px;
  }

  .order-combo .combo-detail {
    padding: 22px 17px 11px 18px;
  }
  .product-lister {
    padding: 16px 11px 13px 11px;
  }
  .rating-main .buy-now {
    font-size: 13px;
    padding: 3px 8px;
  }
  .rating-main .rating i {
    font-size: 11px;
  }
}

@media (max-width: 1199.98px) {
  .top-header {
    padding: 9px 14px;
  }
  .result-heading p {
    font-size: 18px;
  }
  .result-heading h2 {
    font-size: 22px;
  }
  .filter-listing label {
    font-size: 15px;
  }
  .filter-sidebar .filter-listing h2 {
    font-size: 18px;
  }
  .list-view-order ul li figure {
    width: 121px !important;
    max-width: 121px !important;
  }
  .inclusiv-main .light-btn {
    padding: 5px 13px;
  }
  .list-view-order ul li figcaption {
    width: calc(100% - 121px) !important;
  }
  .list-view-order ul figcaption h6 a {
    font-size: 18px;
  }
  .product-related-sec h2 {
    font-size: 20px;
  }
  .delivery-sec figure {
    width: 59px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .delivery-ad-radio a {
    font-size: 17px;
  }
  .custom_radio p {
    font-size: 17px;
  }
  .delivery-ad-radio {
    padding: 6px 18px;
  }
  .steper {
    font-size: 18px;
    padding: 5px 9px;
  }
  .search-order input[type="text"] {
    width: 187px;
    flex: 0 0 187px;
  }
  .past-order .btn,
  .past-order select {
    width: 41%;
  }
  .common-heading h2 {
    font-size: 20px;
  }
  .track-package li a {
    padding: 6px 9px;
    font-size: 13px;
    width: 129px;
  }
  .order-info-sec .order-info li {
    font-size: 14px;
  }
  .order-all-info a {
    font-size: 14px;
  }
  .publisher-list li {
    font-size: 16px;
  }
  .product-full-detail strong {
    font-size: 18px;
  }
  .product-full-detail h2 {
    font-size: 22px;
  }
  .product-full-detail p {
    line-height: 19px;
    font-size: 16px;
  }
  .seller-info h6,
  .seller-info p {
    font-size: 16px;
  }
  .color-plate a {
    margin-right: 0.2rem;
  }
  .seller-qnt-des .product-selection .qunty {
    margin-right: 18px;
  }
  .productdesc-order ul figcaption .in-stock {
    font-size: 16px;
  }
  .inclusive-text {
    font-size: 30px !important;
  }
  .product-rating img {
    margin-right: 7px;
    max-width: 20px;
  }
  .productdesc-order ul figcaption span {
    font-size: 16px;
    line-height: 26px;
  }
  .product-desc .image-gallery-thumbnail {
    width: 83px;
  }
  .product-desc .image-gallery-thumbnail {
    width: 70px;
  }
  .history-sec ul li {
    max-width: calc(33.33% - 13px);
  }
  .common-heading h2 {
    font-size: 24px;
  }
  .order-combo .combo-detail figcaption h2 {
    font-size: 19px;
  }
  .order-combo .combo-detail figcaption span {
    font-size: 18px;
  }
  .account-detail ul li small {
    font-size: 14px;
  }
  .account-detail ul li small {
    font-size: 14px;
  }
  .account-detail ul li {
    font-size: 16px;
  }
  .account-detail-sec .orders-list {
    padding-left: 6px;
  }
  .top-header form {
    margin-left: 33px;
    width: 244px;
  }
  header .main-header a {
    color: #fff !important;
    margin-right: 9px;
  }

  .dis-box h2 {
    font-size: 18px;
    line-height: 25px;
  }
  .dis-box span {
    font-size: 14px;
  }
  .dis-box {
    padding: 12px 13px 3px 14px;
  }
  .common-slider .slick-next {
    right: -26px;
  }
  .heading span {
    font-size: 24px;
  }
  .recommand-sec figure h2 {
    font-size: 23px;
  }
  .slider-heading h2 {
    font-size: 16px;
  }
  .offer-dis-sec .offer-box h5 {
    font-size: 16px;
    line-height: 24px;
  }
  .copyright span {
    font-size: 14px;
  }
  .recommand-sec figcaption span {
    font-size: 18px;
  }
  .heading h2 {
    font-size: 31px;
  }
  .common-slider .slick-prev {
    left: -34px;
  }
}

@media (max-width: 991.98px) {
  .mobile-cart-sec .top-cart li img {
    filter: brightness(0) invert(1);
  } 
  .inner-banner .dis-box h2 {
    font-size: 29px;
    line-height: 29px;
  }
  .inner-banner .dis-box {
    padding: 14px 20px;
  }
  .seller-tag {
    padding: 4px 11px;
    font-size: 16px;
    width: 106px;
}
  .inclusiv-main {
    margin-bottom: 18px;
}
  .list-view-order ul li {
    flex-direction: row!important;
}
.list-view-order ul li figure {
  width: 98px !important;
  max-width: 98px !important;
}
.list-view-order ul li figcaption {
  width: calc(100% - 98px) !important;
}
  .filter-icon {
    position: absolute;
    top: 4px;
    right: 16px;
    background: #005062;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 32px;
    border-radius: 100%;
    color: #fff;
    font-size: 13px;
}
  .filter-icon{
    position: absolute;
  }
  .openfilter {
    left: 0%!important;
  }
  .filter-mobile {
    display: inline-block;
    background: #005062;
    color: #fff;
    padding: 5px 15px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .grid-feature a {
    padding: 9px 17px;
  }
  .result-heading {
    margin-bottom: 9px;
  }
  .all-result .result-showing {
    padding: 18px 15px;
  }
  .filter-sidebar {
    position: fixed;
    left: 0;
    width: 300px;
    left: -300px;
    transition: 0.4s;
    background: #fff;
    z-index: 5;
    padding: 32px 12px;
    top: 0;
    border-right: 0;
    box-shadow: 0 2px 16px 0 hsl(0deg 0% 46% / 35%);
    bottom: 0;
    overflow-y: auto;
  }
  .product-rel-main {
    margin-top: 10px;
  }
  .select-payment-method .position-static .custom_radio {
    font-size: 16px;
  }
  .orders-list ul li figure {
    width: 70px;
    max-width: 70px;
  }
  .orders-list ul figcaption {
    width: calc(100% - 70px);
  }
  .order-info-sec table {
    width: 813px;
  }
  .order-history-bredcum {
    margin-bottom: 8px;
  }
  .past-order .btn,
  .past-order select {
    width: 28%;
  }
  .product-desc .image-gallery-thumbnail {
    width: 50px;
  }
  .productdesc-order ul li {
    flex-direction: column;
  }
  .productdesc-order .history-sec ul li {
    max-width: calc(33.33% - 13px);
  }
  .order-combo .combo-detail figcaption span {
    font-size: 15px;
  }
  .order-combo .combo-detail figcaption h2 {
    font-size: 18px;
  }
  .order-combo .combo-detail {
    flex-direction: column;
  }
  .order-combo .combo-detail figure {
    max-width: 59px;
    width: 59px;
  }
  .order-combo .combo-detail figcaption {
    margin-left: 0px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .account-heading {
    font-size: 22px;
  }
  .account-detail-sec .orders-list {
    padding-left: 0px;
    margin-top: 12px;
  }
  .main-header .navbar-nav {
    padding-bottom: 10px;
  }
  .main-header .navbar-collapse .dropdown-menu[data-bs-popper] {
    right: 0;
    position: relative;
  }
  .top-header form {
    z-index: 2;
  }
  .sidebar.sidepanel {
    max-width: 100%;
  }
  .sidebar {
    width: 100%;
  }
  .main-header .navbar-collapse .dropdown-toggle img {
    display: none;
  }
  .offer-dis-sec .offer-box h5 {
    font-size: 20px;
  }
  .recommand-sec .container {
    padding: 0px 20px;
  }
  .recommand-sec figcaption {
    max-width: 232px;
  }
  .recommand-sec figcaption span {
    font-size: 16px;
  }
  .recommand-sec figure h2 {
    bottom: 94px;
  }
  .dis-sec {
    margin-bottom: 23px;
  }
  .mobile-cart-sec .top-cart li {
    margin-right: 1px;
  }
  .for-mobile-header {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .main-header .navbar-toggler:focus {
    box-shadow: none;
  }
  .main-header .navbar-collapse {
    position: absolute;
    background: #005062;
    width: 100%;
    left: 0;
    top: 43px;
    z-index: 5;
  }

  .main-header .navbar-collapse .nav-link {
    padding: 8px 16px;
  }
  .main-header .navbar-toggler {
    /* background: #fff; */
    padding: 0;
    border-radius: 0px;
    border: none;
  }

  .main-header .navbar-toggler-icon {
    filter: brightness(4) invert(1);
    width: 1.4rem;
    height: 1.4em;
  }
}

@media (max-width: 767.98px) {
  .dis-sec {
    margin-top: 27px;
  }
  .shoping-cart-sec form .light-btn {
    font-size: 15px;
    padding: 5px 23px;
  }
  .subtotal h2 {
    font-size: 18px;
  }
  .checkout-total h2 {
    font-size: 20px;
  }
  .product-related-sec ul figcaption h3 {
    font-size: 18px;
  }
  .checkout-main-sec .delivery-sec.py-3 {
    padding: 0px 16px;
  }
  .past-order .btn,
  .past-order select {
    width: 100%;
  }
  .seller-qnt-des {
    margin-top: 12px;
  }
  .seller-qnt-des .btn {
    width: 196px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .productdesc-order ul li {
    align-items: normal !important;
  }
  .seller-qnt-des .seller-dflex {
    justify-content: normal !important;
  }
  .seller-qnt-des .seller-dflex .color-plate {
    margin-right: 10px;
  }
  .seller-qnt-des .seller-dflex .color-plate:last-child {
    margin-right: 0px;
  }
  .productdesc-order ul figcaption span {
    font-size: 16px;
    line-height: 23px;
  }
  /* .seller-qnt-des .d-flex {
    flex-direction: column;
    align-items: normal !important;
  } */
  .seller-qnt-des .btn {
    display: block;
  }
  .productdesc-order {
    margin-top: 17px;
  }
  .productdesc-order ul figcaption h6 a {
    font-size: 18px;
  }
  .product-desc .image-gallery-thumbnail {
    width: 82px;
  }
  .account-detail ul li small {
    font-size: 13px;
  }
  .product-full-detail h2 {
    font-size: 18px;
  }
  .product-full-detail strong {
    font-size: 16px;
  }
  .account-heading {
    font-size: 20px;
  }
  .account-detail ul li {
    line-height: 25px;
  }
  .history-sec ul li {
    max-width: calc(50% - 13px);
  }
  .order-combo .combo-detail {
    padding: 13px 11px 8px 11px;
  }
  .order-combo .combo-detail figcaption span {
    font-size: 14px;
  }
  .top-header .form-search input {
    padding: 5px 25px;
  }
  .top-header form.mobilesearch {
    transform: translate(0);
  }
  .top-profile figure {
    width: 30px;
    height: 30px;
  }
  .top-profile {
    padding-left: 8px;
    border-left: none;
  }
  .top-profile figcaption {
    width: calc(100% - 30px);
  }
  .top-header form {
    margin-left: 0px;
    left: 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    padding: 3px 16px;
    background: #fff;
    transform: translateY(-100%);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
  .mobile-cart-sec .top-cart li img {
    max-width: 21px;
  }
  .dis-box span {
    font-size: 13px;
  }
  .dis-sec figcaption {
    border-radius: 11px;
  }
  .recommand-sec figure h2 {
    font-size: 19px;
  }
  .offer-dis-sec .offer-box {
    background: #4361ee;
    height: 140px;
    padding: 16px;
  }
  footer a img {
    max-width: 120px;
  }
  .offer-dis-sec .offer-box h5 {
    font-size: 17px;
  }
  footer {
    min-height: 165px;
  }
  .recommand-sec figure h2 {
    bottom: 54px;
  }
  .recommand-sec figcaption span {
    font-size: 14px;
  }
  .recommand-sec figcaption {
    max-width: 182px;
    padding: 8px 16px;
  }
}

@media (max-width: 575.98px) {
  .dis-sec {
    margin-bottom: 15px;
  }
  .comon-modal .modal-body {
    padding: 21px 22px 12px 22px;
}
.forget-password a{
  font-size: 14px;
}
.comon-modal .btn{
  height: 45px;
}
.comon-modal .modal-body span {
  font-size: 15px;
}
.forget-password .form-check-label{
  font-size: 14px;
}
.comon-modal .modal-dialog {
  border-radius: 11px;
}
.comon-modal .modal-body .form-control{
  height: 45px;
}
.comon-modal .modal-title.h4 {
  font-size: 20px;;
}
  .inclusiv-main .light-btn {
    font-size: 14px;
}
  .inner-banner figure {
    height: 169px;
}
.inner-banner .dis-box {
  padding: 10px 13px;
}
.inner-banner .dis-box h2 {
  font-size: 20px;
  line-height: 29px;
}
  .all-result .result-showing {
    padding: 15px 10px;
}
.result-heading h2 {
  font-size: 18px;
}

.bredcum-sec .breadcrumb {
  margin-bottom: 0px;
}
.bredcum-sec {
  padding: 12px 0px;
}
.bredcum-sec li{
  font-size: 14px;
}
.grid-feature select {
  width: 179px;
  font-size: 15px;
  padding: 11px;
}
.grid-feature select {
  width: 179px;
  font-size: 15px;
  padding: 7px;
}
.grid-feature a {
  padding: 6px 12px;
}
.result-heading p {
  font-size: 14px;
}
  .list-view-order .light-btn{
    font-size: 15px;
  }
  .list-view-order ul li figure {
    width: 68px !important;
    max-width: 68px !important;
}
.list-view-order ul figcaption h6 a {
  font-size: 16px;
}
.list-view-order ul li figcaption {
  width: calc(100% - 68px) !important;
}
  .product-related-sec h2 {
    font-size: 18px;
  }
  .subtotal h2 {
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  .shoping-cart-sec .seller-qnt-des .product-selection {
    justify-content: normal !important;
    margin-bottom: 10px !important;
  }
  .shoping-cart-sec .seller-qnt-des form {
    padding: 0px 0px !important;
  }
  .cart-qunt-flex {
    flex-direction: column;
  }
  .delivery-ad-radio a {
    font-size: 14px;
  }
  .steper {
    font-size: 15px;
    padding: 6px 15px;
  }
  .custom_radio .radio_indicator {
    height: 15px;
    width: 15px;
  }
  .custom_radio p {
    font-size: 14px;
    line-height: 19px;
  }
  .custom_radio {
    font-size: 16px;
  }
  .product-related-sec {
    padding: 12px 24px;
  }
  .product-related-sec ul figcaption h5 {
    font-size: 14px;
  }
  .product-related-sec ul li figure {
    width: 47px;
  }
  .product-related-sec ul figcaption {
    width: calc(100% - 47px);
  }
  .green-bg {
    padding: 6px 29px;
    font-size: 15px;
  }
  .order-info li {
    font-size: 14px;
  }
  .checkout-heading {
    font-size: 19px;
  }
  .order-history-bredcum {
    margin-bottom: 12px;
  }
  .past-order .btn,
  .past-order select {
    margin: 0px 0px 11px 0px;
  }
  .order-breadcum .search-order {
    flex-direction: column;
    align-items: normal !important;
  }

  .search-order input[type="text"] {
    flex: 1 1;
    width: 100%;
    margin-bottom: 11px;
  }

  .past-order {
    margin-left: 0px;
    flex-direction: column-reverse;
  }
  .past-order .btn {
    height: 36px;
  }
  .inclusive-text {
    font-size: 22px !important;
  }
  .seller-tag {
    padding: 3px 8px;
    font-size: 16px;
    width: 100px;
  }
  .product-full-detail p {
    line-height: 19px;
    font-size: 14px;
  }
  .productdesc-order ul figcaption span {
    font-size: 14px;
    line-height: 23px;
  }
  .productdesc-order ul figcaption .in-stock img {
    max-width: 22px;
  }
  .productdesc-order ul figcaption .in-stock {
    font-size: 14px;
  }
  .product-rating img {
    margin-right: 4px;
    max-width: 17px;
  }
  .seller-info h6,
  .seller-info p {
    font-size: 14px;
  }
  .seller-info p {
    line-height: 20px;
  }
  .product-desc .row {
    padding: 41px 0px 5px 0px;
  }
  .result-bredcum {
    padding: 0px 10px;
  }
  .inclusive-text span {
    font-size: 15px !important;
  }
  .color-plate span {
    font-size: 12px;
  }
  .inclusive-text small {
    font-size: 14px !important;
  }
  .common-slider figcaption h2 {
    font-size: 11px;
  }
  .account-heading {
    font-size: 18px;
  }
  .common-heading h2 {
    font-size: 18px;
  }
  .history-sec ul li {
    max-width: calc(50% - 6px);
    margin-right: 6px;
  }
  .product-lister a span {
    font-size: 14px;
  }
  .price-detail span {
    font-size: 18px;
  }
  .rating-main .buy-now {
    font-size: 11px;
    padding: 3px 3px;
  }
  .rating-main .rating i {
    margin-right: 2px;
  }
  .order-combo .combo-detail {
    margin-bottom: 14px;
    padding: 23px 11px 23px 11px;
  }
  .orders-list ul figcaption h2 {
    font-size: 22px;
  }
  .orders-list ul li figure {
    width: 60px;
    max-width: 60px;
  }
  .orders-list ul figcaption {
    width: calc(100% - 60px);
  }
  .account-detail .account-detail-flex {
    flex-direction: column;
  }
  /* .recommand-sec figure:last-child {
    margin-bottom: 0px !important;
  } */
  .heading h2 {
    font-size: 28px;
  }
  .slider-heading h2 {
    font-size: 15px;
  }
  .recommand-sec figure {
    margin-bottom: 14px !important;
  }
  .heading h2 {
    font-size: 23px;
  }
  .heading span {
    font-size: 22px;
  }
}

@media(max-width:400px)
{
  .history-sec ul li {
    max-width: 100%;
}
.product-lister a figure{
  text-align: center;
}
}

@media (max-width: 375px) {
  .common-slider figcaption h2 {
    font-size: 11px;
  }
  .slider-heading h2 {
    font-size: 13px;
  }
}
