.header-flag-options {
  display: flex;
  width: 3em;
  justify-content: space-around;
  align-items: center;
  margin-right: 1em;
}

.flag-square {
  height: 1em;
  width: 1em;
}
