* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

body {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  background-color: #f1f5f4;
}

/* teko-light */

@font-face {
  font-family: "Teko Light";
  src: url("../font/Teko/Teko Light.eot");
  src: url("../font/Teko/Teko Light.eot?#iefix") format("embedded-opentype"),
    url("../font/Teko/Teko Light.woff2") format("woff2"),
    url("../font/Teko/Teko Light.woff") format("woff"),
    url("../font/Teko/Teko Light.svg#Teko Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0912;
}

@font-face {
  font-family: "Teko SemiBold";
  src: url("../font/Teko/Teko SemiBold.eot");
  src: url("../font/Teko/Teko SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../font/Teko/Teko SemiBold.woff2") format("woff2"),
    url("../font/Teko/Teko SemiBold.woff") format("woff"),
    url("../font/Teko/Teko SemiBold.svg#Teko SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0912;
}

/* teko-light */

/* lato-bold */

@font-face {
  font-family: "Lato";
  src: url("../font/font1/Lato Bold.eot");
  src: url("../font/font1/Lato Bold.eot?#iefix") format("embedded-opentype"),
    url("../font/font1/Lato Bold.woff2") format("woff2"),
    url("../font/font1/Lato Bold.woff") format("woff"),
    url("../font/font1/Lato Bold.svg#Lato Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}
/* lato-bold */

/* lato-black */

@font-face {
  font-family: "Lato";
  src: url("../font/font1/Lato Black.eot");
  src: url("../font/font1/Lato Black.eot?#iefix") format("embedded-opentype"),
    url("../font/font1/Lato Black.woff2") format("woff2"),
    url("../font/font1/Lato Black.woff") format("woff"),
    url("../font/font1/Lato Black.svg#Lato Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

/* lato-black */

/* lato-regular */

@font-face {
  font-family: "Lato";
  src: url("../font/font2/Lato\ Regular.eot");
  src: url("../font/font2/Lato Regular.eot?#iefix") format("embedded-opentype"),
    url("../font/font2/Lato Regular.woff2") format("woff2"),
    url("../font/font2/Lato Regular.woff") format("woff"),
    url("../font/font2/Lato Regular.svg#Lato Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

/* lato-regular */

/* lato-semibold */

@font-face {
  font-family: "Lato";
  src: url("../font/font2/Lato Semibold.eot");
  src: url("../font/font2/Lato Semibold.eot?#iefix") format("embedded-opentype"),
    url("../font/font2/Lato Semibold.woff2") format("woff2"),
    url("../font/font2/Lato Semibold.woff") format("woff"),
    url("../font/font2/Lato Semibold.svg#Lato Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

/* lato-semibold */

/* lato-medium */

@font-face {
  font-family: "Lato";
  src: url("../font/font2/Lato Medium.eot");
  src: url("../font/font2/Lato Medium.eot?#iefix") format("embedded-opentype"),
    url("../font/font2/Lato Medium.woff2") format("woff2"),
    url("../font/font2/Lato Medium.woff") format("woff"),
    url("../font/font2/Lato Medium.svg#Lato Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

/* lato-medium */

/* lato-heavy */

@font-face {
  font-family: "Lato";
  src: url("../font/font2/Lato Heavy.eot");
  src: url("../font/font2/Lato Heavy.eot?#iefix") format("embedded-opentype"),
    url("../font/font2/Lato Heavy.woff2") format("woff2"),
    url("../font/font2/Lato Heavy.woff") format("woff"),
    url("../font/font2/Lato Heavy.svg#Lato Heavy") format("svg");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

/* lato-heavy */

/* lato-extra-bold */

@font-face {
  font-family: "Lato";
  src: url("../font/font2/Lato ExtraBold.eot");
  src: url("../font/font2/Lato ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../font/font2/Lato ExtraBold.woff2") format("woff2"),
    url("../font/font2/Lato ExtraBold.woff") format("woff"),
    url("../font/font2/Lato ExtraBold.svg#Lato ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

/* lato-extra-bold */

/* header */

.top-header {
  padding: 15px 22px;
}
.top-header .form-search input {
  border: 0;
  border-bottom: 2px solid #017d99;
  border-radius: 0;
}

.top-header .form-search input:focus {
  box-shadow: none;
}
.banner-sec {
  box-shadow: 4px 4px 19px 0px rgb(0 0 0 / 17%);
}
.top-header form {
  margin-left: 45px;
  width: 305px;
}
.form-search img {
  left: 0;
}
.form-search img,
.form-search .dropdown {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.form-search .dropdown {
  right: 0;
  z-index: 2;
}
.form-search .dropdown .btn {
  padding: 0px;
  color: #017d99;
  font-weight: 800;
  font-size: 14px;
}
.account-detail-sec .orders-list {
  padding-left: 57px;
}
.form-search .dropdown .btn:focus {
  box-shadow: none;
}
.top-header .form-search input {
  border: 0;
  border-bottom: 2px solid #017d99;
  border-radius: 0;
  padding: 2px 25px;
  background: transparent;
}

::placeholder {
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 13px;
  color: #666666;
}
.top-cart li a {
  text-decoration: none;
  font-weight: 800;
  color: #017d99;
  display: flex;
  align-items: center;
}
.top-cart li {
  display: inline-block;
  margin-right: 25px;
}
.top-cart li a span {
  display: inline-block;
  margin-right: 5px;
}

.top-profile {
  display: flex;
  align-items: center;
  border-left: 2px solid rgb(102 102 102 / 56%);
  padding-left: 14px;
}
.top-profile figure {
  margin: 0;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  overflow: hidden;
}
.top-profile figcaption {
  width: calc(100% - 42px);
  padding: 0px 12px 0px 8px;
}
.top-profile figcaption h6 {
  font-size: 14px;
  color: #017d99;
  font-weight: 600;
  margin: 0;
  line-height: 20px;
}

.top-profile figcaption h6 span {
  display: block;
  font-size: 14px;
  color: #666666;
  font-weight: 800;
}

.top-profile figcaption a {
  color: #f77b77;
  font-size: 10px;
  text-decoration: none;
  font-weight: 500;
  display: block;
}
header .main-header {
  background: #005062;
  box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 25%);
  position: relative;
}
header .main-header a {
  color: #fff !important;
  margin-right: 25px;
}
.main-header .nav-link {
  font-weight: 600;
}
.navbar-nav .navbar-nav a {
  margin-right: 25px;
}
.navbar-nav .dropdown-item {
  color: #000 !important;
}

/* header */

/* dis-sec */

.dis-box {
  background: rgb(26 83 92 / 0.9);
  color: #fff;
  padding: 20px 13px 7px 19px;
  font-weight: 800;
  position: absolute;
  top: 0px;
  border-radius: 0px 0px 24px 0px;
}
.dis-box h2 {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 31px;
}

.dis-box span {
  display: block;
  font-size: 16px;
  letter-spacing: 3px;
}

.dis-sec figcaption {
  border-radius: 28px;
  overflow: hidden;
  box-shadow: 4px 4px 10px 0px rgb(0 0 0 / 25%);
}
.dis-sec {
  margin-bottom: 40px;
  margin-top: -65px;
}

.dis-sec figcaption a {
  position: absolute;
  right: 0;
  z-index: 2;
  bottom: 32px;
  text-decoration: none;
  color: #fff;
  background: #017d99cf;
  padding: 5px 9px;
  font-size: 12px;
  border-radius: 9px 0px 0px 9px;
  display: block;
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 25%);
  font-weight: 500;
}

/* dis-sec */

.heading span {
  display: inline-block;
  font-size: 33px;
  color: #717171;
  font-family: "Teko Light";
  font-weight: 300;
}
.heading {
  line-height: 22px;
}
.heading h2 {
  font-size: 45px;
  color: #717171;
  font-family: "Teko SemiBold";
}

/* 23/3/22 */

/* common-slider */

.common-slider figcaption h2 {
  font-size: 13px;
  text-align: center;
  font-weight: 700;
}

.common-slider a {
  text-decoration: none;
  color: #666666;
}

.common-slider .slick-slide > div {
  margin-right: 10px;
}

.common-slider a figure {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 9px;
}

.slider-heading h2 {
  font-size: 18px;
  color: #666666;
  font-weight: 800;
  display: inline-block;
  margin-bottom: 12px;
}
.slider-heading a {
  text-decoration: none;
  font-size: 12px;
  color: #017d99;
  font-weight: 500;
  display: inline-block;
}

.top-deal-slider .top-del {
  background: #fff;
  padding: 16px 12px 8px 12px;
  border-radius: 14px;
  box-shadow: 0 4px 19px 0 rgb(0 0 0 / 8%);
}
/* common-slider */

/* offer-dis-sec */

.offer-dis-sec .offer-box h5 {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  margin: 0;
}

.offer-dis-sec .offer-box {
  background: #4361ee;
  height: 174px;
  border-radius: 7px;
  overflow: hidden;
  padding: 20px;
  color: #fff;
}
/* offer-dis-sec */

/* footer */

footer {
  background-color: #005062;
  padding: 30px 0px;
  min-height: 239px;
  box-shadow: 0 -4px 6px 0 rgb(0 0 0 / 25%);
}

.copyright {
  background: #017d99;
  color: #fff;
  padding: 12px 10px;
  text-align: center;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 25%);
}
.copyright span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
}
.backtop a {
  display: inline-block;
  text-decoration: none;
  background: #016b83;
  color: #fff;
  padding: 4px 13px;
  border-radius: 10px 10px 0px 0px;
  font-size: 13px;
}
.recommand-sec figcaption {
  position: absolute;
  bottom: 26px;
  right: 0;
  background: rgb(0 80 98 / 0.6);
  color: #fff;
  padding: 8px 20px;
  border-radius: 8px 0px 0px 8px;
  overflow: hidden;
  max-width: 304px;
  box-shadow: 0 4px 19px 0 rgb(0 0 0 / 8%);
}
.recommand-sec figure {
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
  border-radius: 16px;
  overflow: hidden;
}
.recommand-sec figure h2 {
  position: absolute;
  bottom: 131px;
  font-size: 39px;
  font-weight: 800;
  color: #fff;
  padding: 10px 40px;
}
.recommand-sec .container {
  padding: 0px 70px;
}
.fig2 {
  background: #c4c4c4d1;
}
.recommand-sec figcaption span {
  display: block;
  font-size: 22px;
  font-weight: 800;
}
/* footer */

/* account-list */
.account-detail-sec {
  background-color: #fafcfb;
  border-radius: 9px;
  overflow: hidden;
  padding: 22px 0px 11px 0px;
}
.rating-rate1 {
  font-size: 13px;
  color: #5e6366;
  font-weight: 500;
}
.account-detail ul li {
  font-size: 20px;
  color: #666666;
  font-weight: 500;
  line-height: 30px;
}

.account-detail ul li small {
  font-weight: bold;
  font-size: 20px;
}
.account-heading {
  font-size: 24px;
  color: #666666;
  font-weight: 800;
}

.order-combo .combo-detail {
  background: #fafcfb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 17px 11px 30px;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 5%);
}

.order-combo .combo-detail figcaption {
  margin-left: 24px;
  width: calc(100% - 96px);
}

.order-combo .combo-detail figcaption h2 {
  font-size: 24px;
  font-weight: 800;
  color: #666666;
}

.order-combo .combo-detail figcaption span {
  display: block;
  font-weight: 500;
  font-size: 21px;
  color: #666666;
}

.order-combo .combo-detail figure {
  max-width: 96px;
  width: 96px;
}

/* account-list */

/* common-heading */

.common-heading h2 {
  color: #666666;
  font-size: 30px;
  font-weight: 800;
}

/* common-heading */

/* products-listing */

.product-lister {
  background: #fff;
  padding: 16px 16px 13px 16px;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
}
.common-heading h2 span {
  display: inline-block;
  color: #4895ef;
}
.product-lister a {
  text-decoration: none;
  font-size: 16px;
  color: #19191d;
  display: block;
  margin-bottom: 12px;
}

.price-detail span {
  font-size: 24px;
  font-weight: 800;
  display: block;
}
.dis-offer-card {
  margin-bottom: 6px;
}

.price-detail p {
  font-size: 14px;
  color: #787885;
}

.rating-main .rating i {
  color: orange;
  margin-right: 4px;
  font-size: 11px;
}

.rating-main .buy-now {
  font-size: 13px;
  font-weight: bold;
  color: #4895ef;
  border: 1px solid #9dc2ff;
  padding: 3px 12px;
  border-radius: 4px;
  overflow: hidden;
}
.order-info-sec table td:last-child {
  border-right: none;
}
.product-lister a span {
  margin-top: 10px;
}
.history-sec ul li {
  max-width: 247px;
}

.history-sec ul li {
  max-width: calc(20% - 13px);
  margin-right: 13px;
  margin-bottom: 10px;
}
/* products-listing */

/* order-list */

.orders-list ul li figure {
  width: 150px;
  max-width: 150px;
}

.orders-list ul figcaption {
  width: calc(100% - 150px);
  /* margin-left: 13px; */
  padding-left: 14px;
}

.orders-list ul figcaption h6 {
  font-size: 17px;
  font-weight: 700;
}
.orders-list ul figcaption h6 a {
  text-decoration: none;
  color: #666666;
  font-size: 15px;
}
.orders-list ul figcaption span {
  display: block;
  font-size: 14px;
  line-height: 23px;
}

.orders-list ul figcaption .author-name {
  color: #666666;
}

.orders-list ul .delivery-date {
  color: #f77b77;
}

.orders-list ul figcaption h2 {
  font-size: 24px;
  font-weight: 900;
  color: #666666;
}

.orders-list ul figcaption h2 span {
  display: block;
  font-size: 16px;
  color: #4895ef;
  font-weight: 600;
}

/* order-list */

/* shoping-cart */

.shoping-cart-sec form {
  background: #fafcfb;
  padding: 12px;
  border-radius: 9px;
  box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 5%);
}
.shoping-cart-sec form input[type="checkbox"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.shoping-cart-sec .orders-list {
  margin-left: 30px;
}

.shoping-cart-sec form input[type="checkbox"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}
.delete-order li {
  display: inline-block;
  margin-right: 16px;
}

.delete-order li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 699;
  color: #666666;
  display: inline-flex;
}
.delete-order li:last-child {
  margin-right: 0px;
}
.text-danger {
  color: #f77b77 !important;
}
.shoping-cart-sec hr {
  margin-left: 31px;
  color: #00000038;
}
/* shoping-cart */

.product-related-sec ul li figure {
  width: 73px;
}

.product-related-sec ul li {
  display: flex;
  border-bottom: 1px solid #eaeceb;
  margin: 13px 0px;
  padding-bottom: 13px;
}
.product-related-sec ul figcaption {
  width: calc(100% - 73px);
  padding-left: 20px;
}

.product-related-sec ul figcaption h5 {
  font-size: 16px;
  color: #19191d;
}
.delivery-ad-radio {
  padding: 10px 30px;
}
.product-related-sec ul figcaption h3 {
  font-size: 24px;
  font-weight: 800;
}

.product-related-sec ul figcaption h3 a {
  text-decoration: none;
  font-size: 14px;
  color: #4895ef;
  font-weight: 600;
}

.product-related-sec h2 {
  color: #666666;
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 14px;
}

.product-related-sec {
  background: #fff;
  padding: 22px 24px;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 5%);
}

.subtotal h2 {
  font-size: 24px;
  color: #666666;
  font-weight: 600;
}

.light-btn {
  background: #faa381;
  border-color: transparent;
  font-size: 18px;
  border-radius: 13px;
  padding: 5px 51px;
  margin-left: 15px;
}

/* 23/2/22 */

/* 24/2/22 */

.order-breadcum .order-history-bredcum li {
  display: inline-block;
  border-right: 1px solid #4895ef;
  padding: 0px 7px;
}
.order-breadcum .order-history-bredcum li:first-child {
  border-color: #faa381;
}
.order-breadcum .order-history-bredcum li a {
  text-decoration: none;
  font-size: 11px;
  color: #4895ef;
  font-weight: 600;
}
.order-breadcum .order-history-bredcum li .order {
  color: #faa381;
}

.order-breadcum .order-history-bredcum li {
  display: inline-block;
  /* margin-right: 10px; */
  border-right: 1px solid #4895ef;
  padding: 0px 7px;
}

.order-breadcum .order-history-bredcum li a {
  text-decoration: none;
  font-size: 11px;
  color: #4895ef;
  font-weight: 600;
}

.search-order input[type="text"] {
  width: 247px;
  height: 25px;
  background: transparent;
  font-size: 12px;
  border-color: #000;
  color: #666666;
  flex: 0 0 247px;
}

.past-order {
  flex: 1;
  display: flex;
  margin-left: 13px;
  align-items: center;
}

.past-order .btn,
.past-order select {
  width: 35%;
  margin: 0px 7px;
}

.past-order .btn {
  background: #005062;
  border-color: transparent;
  font-size: 14px;
  font-weight: 800;
  border-radius: 4px;
  padding: -1px 0px;
  height: 33px;
}

.past-order select {
  background: transparent;
  font-size: 13px;
  border-color: #000;
  color: #666666;
  letter-spacing: 1px;
  padding: 7px 9px;
  background-image: url(../images/past-icon.svg);
  background-repeat: no-repeat;
  background-position: right 7px center;
}
.green-text {
  color: #005062 !important;
}
.order-breadcum .order-history-bredcum li:last-child {
  border-right: none;
}

/* order-info-sec */

.order-info-sec .order-info li {
  list-style: none;
  color: #666666;
  font-size: 16px;
  font-weight: 600;
}
.order-all-info a {
  color: #4895ef;
  font-size: 16px;
  text-decoration: none;
  margin-right: 20px;
}
.order-all-info a:last-child {
  margin-right: 0px;
}

.order-info li {
  list-style: none;
  color: #666666;
  font-size: 16px;
  font-weight: 600;
}

.track-package li a {
  background: antiquewhite;
  background: #017d99;
  color: #fff;
  display: inline-block;
  padding: 6px 15px;
  margin-bottom: 10px;
  border-radius: 9px;
  overflow: hidden;
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

.track-package li {
  margin-bottom: 0px;
  display: block;
  text-align: center;
}
.order-info-sec table td {
  border-right: 1px solid #edefee;
}
/* order-info-sec */

/* productdesc-order */

.productdesc-order ul figcaption h6 a {
  font-size: 26px;
}
.productdesc-order ul figcaption span {
  font-size: 20px;
  line-height: 28px;
}
.productdesc-order ul figcaption .in-stock {
  color: #07b16a;
  font-weight: 800;
  font-size: 18px;
}

.product-rating img {
  cursor: pointer;
  margin-right: 7px;
}
.inclusive-text {
  font-size: 36px !important;
  font-weight: 900;
  color: #666666;
}
.inclusive-text span {
  font-size: 18px !important;
  color: #4895ef !important;
  font-weight: 600;
  display: block;
}
.product-rating span {
  font-size: 18px;
  color: #666666;
  display: block;
  font-weight: 500;
}

.inclusive-text small {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-top: 5px !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border-color: transparent !important;
}
.image-gallery-thumbnail:hover {
  border-color: transparent !important;
}
.result-bredcum a {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
}
.product-desc .row {
  background-color: #fafcfb;
  border-radius: 9px;
  padding: 60px 24px 30px 24px;
}
.seller-info h6,
.seller-info p {
  font-size: 20px;
  color: #666666;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: auto;
}
.seller-info p {
  line-height: 24px;
}
.publisher-list li {
  font-size: 20px;
  margin-bottom: 8px;
  color: #666666;
}
.product-full-detail p {
  line-height: 24px;
  font-size: 20px;
  color: #666666;
}
.product-full-detail h2 {
  color: #666666;
  font-size: 26px;
  font-weight: 600;
}
.product-full-detail strong {
  font-size: 20px;
  color: #666666;
}
/* productdesc-order */

.seller-qnt-des .product-selection {
  display: flex;
  align-items: center;
}

.seller-qnt-des .product-selection .qunty {
  /* min-width: 81px; */
  height: 33px;
  width: 81px;
  font-size: 14px;
  color: #666666;
  font-weight: 700;
  margin-right: 26px;
  padding: 0px 8px 0px 7px;
}

.seller-qnt-des .product-selection .audiobook {
  width: 110px;
  height: 33px;
  font-size: 14px;
  font-weight: 700;
  color: #666666;
  padding: 0px 7px;
}

.seller-tag {
  display: block;
  background: #faa381;
  border-radius: 0px 0px 12px 0px;
  position: absolute;
  top: 0px;
  left: 0;
  color: #fff;
  padding: 4px 15px;
  font-size: 18px;
  width: 116px;
  box-shadow: 0px 4px 4px 2px rgb(0 0 0 / 14%);
  z-index: 4;
}
.common-color {
  background: #4895ef;
}
.product-selection .form-control {
  border-radius: 14px;
  overflow: hidden;
}
.seller-qnt-des .btn {
  width: 225px;
  margin-bottom: 20px;
}
.product-selection .form-select {
  border-radius: 6px;
  overflow: hidden;
  border-color: #666666;
}

.checkout-total li {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666666;
  padding-bottom: 5px !important;
}

.checkout-total h2 {
  font-weight: 800;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  color: #f77b77;
}

.checkout-heading {
  color: #666666;
  font-size: 23px;
  font-weight: 800;
}

.steper {
  font-size: 25px;
  background: #005062;
  border-radius: 32px;
  padding: 4px 17px;
  color: #fff;
  font-weight: 800;
}
/* 24/2/22 */

/* 25/2/22 */

.custom_radio .checkout-input:checked ~ .checked_outer {
  border: 3px solid #017d99;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
}

.checked_outer {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  top: 0;
  border: 3px solid #017d997d;
  box-shadow: none;
  border-radius: 14px;
}

/* join-form */

.custom_radio {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-right: 30px;
  display: block;
  width: 100%;
  font-size: 21px;
  font-weight: 600;
  border-radius: 14px;
  margin: 0 auto;
  padding: 15px 20px 15px 23px;
  color: #017d99;
}
.select-payment-method .position-static .custom_radio {
  color: #666666;
  font-size: 20px;
}
.green-bg {
  background: #07b16a;
  padding: 9px 51px;
}
.delivery-ad-radio a {
  text-decoration: none;
  color: #4895ef;
  font-weight: 600;
  font-size: 21px;
  display: block;
  text-align: right;
}
.custom_radio .checkout-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -29px;
  cursor: pointer;
  margin: 0;
  z-index: 2;
}

.custom_radio .radio_indicator {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #c4c4c4;
  position: absolute;
  top: 23px;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  left: -29px;
}
.custom_radio .checkout-input:checked + .radio_indicator {
  border-color: #c4c4c4;
}

.custom_radio .checkout-input:checked + .radio_indicator:before {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #017d99;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -7px 0 0 -7px;
  content: "";
}

.custom_radio p {
  display: block;
  font-size: 21px;
  color: #666666;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 8px;
}
.checkout-order-list ul {
  border-bottom: 1px solid #00000030;
  padding-bottom: 5px;
}
.select-payment-method .radio_indicator {
  transform: translateY(0%);
}
.item-delivery-radio {
  position: absolute;
  left: -37px;
  top: 50%;
  transform: translateY(-50%);
}
.common-radio {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #c4c4c4;
}
.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  padding: 0px 10px;
}
.inner-banner {
  box-shadow: 4px 4px 10px 0px rgb(0 0 0 / 25%);
}
.inner-banner figure {
  height: 264px;
}
.inner-banner .dis-box {
  padding: 20px 27px;
}
.bredcum-sec {
  padding: 17px 0px;
}
.inner-banner .dis-box h2 {
  font-size: 36px;
  line-height: 35px;
  margin: 0;
}
.bredcum-sec li {
  color: #666666;
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
}

.bredcum-sec li a {
  text-decoration: none;
  color: #666666;
}

/* all-show-result */

.all-show-result .all-result {
  border: 1px solid #00000045;
  border-radius: 8px;
}
.filter-sidebar {
  border-right: 1px solid #00000045;
  padding: 14px 0px;
}
.inclusiv-main {
  padding: 0px 33px;
}
.inclusiv-main .light-btn {
  padding: 5px 34px;
}
.all-result .product-row {
  border-bottom: 1px solid #00000045;
}
.inner-banner figure img {
  height: 100%;
  object-fit: cover;
}
.all-result .result-showing {
  padding: 18px 21px;
  border-bottom: 1px solid #00000045;
}
/* .result-heading {
  padding: 18px 21px;
} */

.result-heading h2 {
  font-size: 24px;
  color: #666666;
  font-weight: 800;
  margin: 0;
}

.result-heading p {
  margin: 0;
  font-size: 20px;
  color: #666666;
  font-weight: 600;
}
.grid-feature a {
  border-radius: 4px;
  background: #fff;
  display: block;
  padding: 9px;
}

.grid-feature select {
  width: 176px;
  font-size: 16px;
  padding: 11px;
  border-radius: 4px;
  color: #017d99;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 15%);
}
.grid-feature a {
  border-radius: 4px;
  background: #fff;
  display: block;
  padding: 9px 25px;
  margin-right: 14px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 15%);
}

.grid-feature select {
  width: 176px;
  font-size: 16px;
  padding: 11px;
  border-radius: 4px;
  color: #017d99;
  font-weight: 600;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 15%);
  border: none;
}

/* all-show-result */
.filter-sidebar .filter-listing h2 {
  font-size: 20px;
  font-weight: 800;
  color: #666666;
}

.filter-listing {
  margin-bottom: 20px;
}
.filter-listing .see-more {
  font-size: 16px;
  text-decoration: none;
  color: #4895ef;
}
.filter-sidebar .filter-listing .form-check {
  font-size: 20px;
  color: #666666;
  font-weight: 500;
}

.filter-sidebar .filter-listing .form-check .form-check-input {
  width: 13px;
  height: 13px;
  border-radius: 2px;
  margin-left: -28px;
  margin-top: 0.5rem;
}

.list-view-order ul figcaption h6 a {
  font-size: 20px;
}
.list-view-order ul figcaption span {
  font-size: 18px;
}
.list-view-order {
  background: #fff;
  padding: 12px;
  border-radius: 9px;
  position: relative;
}
.list-view-order::after {
  content: "";
  background: #7878854a;
  height: 100%;
  width: 1px;
  position: absolute;
  right: -20px;
  top: 0px;
}
.list-view-order ul li figure {
  width: 178px;
  max-width: 178px;
}
.dropdown a {
  font-size: 14px;
  color: #017d99;
  font-weight: 800;
}
.list-view-order ul li figcaption {
  width: calc(100% - 178px) !important;
  padding-left: 18px !important;
}
.main-header .dropdown-menu a:hover {
  color: #005062 !important;
  background-color: #fff;
}
.grid-product-list ul li {
  max-width: calc(25% - 8px);
  margin-right: 8px;
  margin-bottom: 10px;
}
.grid-product-list .rating-main .buy-now {
  font-size: 12px;
}
.grid-product-list .rating-main .rating i {
  color: orange;
  margin-right: 2px;
}
.dropdown a {
  font-size: 14px;
  color: #017d99;
  font-weight: 800;
}

.dropdown-menu {
  border-color: #005062;
  background: #005062;
  color: #fff;
  padding: 0px;
  border-radius: 0px;
  min-width: auto;
  /* z-index: 999999; */
}
.dropdown-menu a:last-child {
  border: none;
}
.dropdown-menu a {
  color: #fff;
  font-weight: 600;
  border-bottom: 1px solid #fff;
  padding: 7px 9px;
}
.dropdown-menu a:hover {
  color: #005062;
}
.language-selector .dropdown-toggle::after {
  position: absolute;
  right: 0px;
  top: 50%;
}
.language-selector .dropdown-toggle {
  position: relative;
}
.footer-lang span {
  border: 2px solid #ffffff73;
  padding: 3px 16px;
  border-radius: 6px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 800;
}
.slick-next:before {
  background-image: url(../images/next-icon.png);
}
.slick-prev:before,
.slick-next:before {
  content: "a" !important;
  color: transparent !important;
  font-size: 32px !important;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1 !important;
}

.common-slider .slick-prev {
  left: -44px;
}
.common-slider .slick-next {
  right: -44px;
}
.common-slider .slick-prev:before {
  background-image: url(../images/prev-icon.png);
}
.dis-offer-card p {
  margin-bottom: 0px;
  background: #ebf7ed;
  font-size: 12px;
  color: #37833b;
  padding: 3px 9px;
}
/* 25/2/22 */

/* 26/2/22 */

/* common-modal */
.comon-modal .password_show_btn {
  position: absolute;
  top: 50%;
  right: 17px;
  background: transparent;
  border: none;
  transform: translateY(-50%);
  color: #838589;
  font-size: 20px;
}

.common-modal .modal-header .modal-title {
  font-size: 32px;
  display: block;
}

.comon-modal .modal-title.h4 {
  font-size: 24px;
  font-weight: 800;
  color: #000;
}

.comon-modal .modal-body {
  padding: 33px 33px 12px 33px;
  background: #e5e5e5;
}
.login-signup span {
  cursor: pointer;
  font-size: 16px;
  color: #017d99;
  font-weight: 600;
}
.comon-modal .modal-body span {
  font-size: 17px;
  color: #838589;
  margin-bottom: 12px;
  display: block;
}

.comon-modal .modal-body .form-control {
  height: 50px;
  border-radius: 10px;
  background: #fff;
  /* padding: 0px 21px; */
}

.comon-modal .form-label {
  font-size: 14px;
  color: #000;
  margin-bottom: 16px;
}
.comon-modal .modal-body .form-control::placeholder {
  font-size: 14px;
  letter-spacing: 1px;
}

.forget-password a {
  text-decoration: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.comon-modal .modal-dialog {
  border-radius: 16px;
  overflow: hidden;
}
/* common-modal */

.comon-modal .btn {
  background: #017d99;
  border-color: #017d99;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 17px;
}
.dont-account a {
  text-decoration: none;
  color: #017d99;
  font-weight: 500;
  margin-left: 1px;
}
.comon-modal .modal-header {
  padding: 0.7rem 1.5rem;
  background: #e5e5e5;
  border-bottom: 1px solid #0003;
}
.dont-account {
  margin-top: 14px;
}

.login-signup span {
  cursor: pointer;
  font-size: 16px;
  color: #017d99;
  font-weight: 600;
}

.forget-password .form-check-label {
  font-size: 16px;
  margin-left: 7px;
}

.forget-password .form-check-input {
  width: 19px;
  height: 19px;
}
.color-plate .active {
  background: #005062;
  box-shadow: 0 0 0 0.1rem #cccccc;
}
.color-plate a {
  background: #bacbd8;
}
.color-plate a {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  margin-right: 0.5rem;
}
.color-plate span {
  display: inline-block;
  margin-right: 14px;
  color: #005062;
  font-weight: 600;
  cursor: pointer;
}
/* 26/2/22 */

/* 28/2/22 */

/* new-csss */
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
.animate {
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.screen_head {
  padding: 14px 8px;
  cursor: pointer;
}
.sidebar.sidepanel {
  max-width: 300px;
}
.breadcrumb-outer1 .breadcrumb li,
.breadcrumb-outer1 .breadcrumb li i {
  font-size: 17px;
}
/* .sidebar_sub_category {
  padding: 8px 17px;
} */
.sidebar_sub_category ul li {
  width: 100%;
  display: block;
  border-bottom: 1px solid #ffffff47;
  background-color: white;
}
.sidebar_sub_category .noicon::after {
  display: none;
}
.main-header .dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}
.main-header .dropdown-menu {
  border: none;
}
.sidebar_sub_category ul li a {
  font-size: 14px;
  color: #fff;
  width: 100%;
  padding: 9px 16px;
  position: relative;
  text-decoration: none;
  display: block;
  background: #005062;
  margin-right: 0px;
}
.sidebar_sub_category .dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}
.screen_head h4 {
  font-size: 16px;
  margin: 0;
  color: black;
}
.sidebar_sub_category ul li a:after {
  color: #ffffff;
  position: absolute;
  content: "\f078";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  display: inline-block;
  border: 0;
  font-size: 13px;
  margin: 0;
  transition: all 0.5s ease;
  top: 9px;
  right: 15px;
  transform: rotate(270deg) !important;
}

.subsubmenu .third_screen {
  opacity: 1;
  visibility: visible !important;
  transition: all 0.5s ease;
  left: 0 !important;
  opacity: 1 !important;
}
.subsubmenu .second_screen {
  opacity: 0;
  visibility: hidden;
}
.sidebar {
  width: 643px;
  background: #fff;
  padding: 0px 0 0;
  /* overflow-y: auto; */
}
.dropdown-menu {
  padding: 0px !important;
}

.third_screen {
  opacity: 0;
  visibility: hidden;
  left: -100% !important;
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
  top: 0;
}
.sidebar_sub_category ul li a:hover::after {
  color: #005062;
}
.custom-relative {
  position: relative;
}
/* new-css */

/* 28/2/22 */
