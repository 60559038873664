.links-footer {
  color: #fff;
  cursor: pointer;
  width: fit-content;
}

.footer-image {
  width: 15%;
  aspectratio: 3/2;
  objectfit: contain;
}

.heading,
a.heading :hover {
  color: #fff;
}

a.heading {
  text-decoration: none;
  width: fit-content;
}

.footer-inline {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
}
